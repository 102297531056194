import { useState, useEffect } from 'react';
import { Grid, Button } from '@mui/material';

import ProductTable from './ProductTable';
import add from '../../../assets/images/add.svg';
import AddProduct from './AddProduct';

import api from '../../../api/index';

export default function Products() {
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [shapaka, setShapaka] = useState({
    page: 1,
    next: null,
    prev: null,
    totalPages: 1,
  });
  const getProducts = async () => {
    const { data } = await api('get', `/products?page=${page}`);
    const { results, ...shapakaItems } = data;
    if (results?.length > 0) {
      setShapaka(shapakaItems);
      setProducts([...products, ...results]);
    } else {
      // eslint-disable-next-line
      console.log('No more data available');
    }
  };

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, [page]);
  const handleLoadMore = () => {
    setPage((prev) => prev + 1);
  };
  return (
    <div>
      <Grid container>
        <Grid xl={2} lg={3} />

        <Grid xl={10} lg={9}>
          <ProductTable
            products={products}
            getProducts={getProducts}
            handleLoadMore={handleLoadMore}
            shapaka={shapaka}
          />
          <div className="btnContainer">
            <Button
              variant="contained"
              fullWidth
              className="addAdminBtn"
              onClick={() => setOpen(true)}
            >
              Add Product &nbsp; &nbsp;
              <img src={add} alt="add" />
            </Button>
          </div>
        </Grid>
      </Grid>
      {open && (
        <AddProduct
          open={open}
          setOpen={setOpen}
          getProducts={getProducts}
        />
      )}
    </div>
  );
}
